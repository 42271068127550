/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:bea11a6c-503e-41cc-8f12-8b0fd9aff37e",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_Gn1nM63M2",
    "aws_user_pools_web_client_id": "7vikvvolc2degeh0oajlg29deg",
    "oauth": {}
};


export default awsmobile;
